import React from 'react'
import { ReactComponent as Logo } from '../assets/images/logo.svg';

function Footer() {
  return (
    <footer id="footer">
        <div className="top">
            <div className="container text-center">
                <p>The formula to efficient energy</p>
                <h2>Get switched on today</h2>
                <a href="/" className="btn btn__primary">Start saving, today! <i className="icon icon__btn"></i></a>
            </div>
        </div>
        <div className="bottom">
            <div className="container">
                <div className="links">
                    <div className="brand">
                        <a href="/" className="logo"><Logo /></a>
                    </div>
                    <div className="contact">
                        <div className="item">
                            <div className="icons"><i className="icon icon__phone"></i></div>
                            <div className="detail">
                                <p>Call us</p>
                                <a href="tel:+27124807100">+27 12 480 7100</a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="icons"><i className="icon icon__mail"></i></div>
                            <div className="detail">
                                <p>Email us</p>
                                <a href="mailto:sales@alumo.co.za">sales@alumo.co.za</a>
                            </div>
                        </div>
                        <div className="social">
                            <a href="https://facebook.com" target="_blank" rel="noreferrer"><i className="icon icon__facebook"></i></a>
                            <a href="https://linkedin.com" target="_blank" rel="noreferrer"><i className="icon icon__linkedin"></i></a>
                            <a href="https://instagram.com" target="_blank" rel="noreferrer"><i className="icon icon__instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container legal">
                <div className="copyright">
                    <p>&copy;Copyright Alumo Energy. All rights reserved. <br /><a href="https://aglet.co.za" target="_blank" rel="noreferrer">Website by Aglet.</a> <a href="https://sacoronavirus.co.za/" target="_blank" rel="noreferrer">SA COVID-19 Portal.</a></p>
                </div>
                <ul>
                    <li><a href="/">Payment Plans</a></li>
                    <li><a href="/">Solutions</a></li>
                    <li><a href="/">Articles</a></li>
                    <li><a href="/">Terms and Conditions</a></li>
                    <li><a href="/">Privacy Policy</a></li>
                </ul>
            </div>
        </div>
    </footer>
  )
}

export default Footer