import React from 'react'

function ArticlesHeader() {
  return (
    <header className="articles__header">
        <div className="text-center container">
            <p>Articles</p>
            <h1>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.</h1>
        </div>
    </header>
  )
}

export default ArticlesHeader