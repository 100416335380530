import { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import ArticlesHeader from './ArticlesHeader';

function Articles() {
    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [catloaed, setCatloaded] = useState(false);
    const [imgUrl, setImgUrl] = useState();
    //const [imgLoaded, setImgLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [articlesPerPage] = useState(9);
    const [filters, setFilters] = useState(articles);
    const [activeId, setActiveId] = useState(0);
    const env = 'http://alumoarticles.agletonline.co.za/wp-json/wp/v2'

    useEffect(() => {
        const fetchArticle = () => {
            axios.get(`${env}/posts`)
            .then(res => {
                setArticles(res.data);
                setLoaded(true);
                setFilters(res.data)
            })
            .catch(err => console.log(err))
        }

        const fetchCategories = () => {
            axios.get(`${env}/categories/`)
            .then(res => {
                setCategories(res.data);
                setCatloaded(true);
            })
            .catch(err => console.log(err))

            
        }
        fetchArticle();
        fetchCategories();

        const fetchImgUrl = () => {
            axios.get(`${env}/media/`)
            .then(res => {
                setImgUrl(res.data);
                //setImgLoaded(true);
            })
            .catch(err => console.log(err));
        } 
        fetchImgUrl();
        
    }, [])

    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = filters.slice(indexOfFirstArticle, indexOfLastArticle);
    const [activePage, setActivePage] = useState(1);
    const pageNumbers = []

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const filterResult = (catItem) => {
        const result = articles.filter((currentData) => {
            return currentData.categories == catItem
        })

        setFilters(result);
        console.log(result);
        setCurrentPage(1);
        setActivePage(1);
    }

    const makeActive = (active) => {
        console.log(this);
    }

    //Pagination

    for(let i = 1; i <= Math.ceil(filters.length / articlesPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div>
            <ArticlesHeader />
            <div className="container articles">
                <div className="categories">
                    <ul>
                        <li><button className={activeId === 0 ? 'active' : ''} onClick={function(event){ setFilters(articles); setActiveId(0) }}>All</button></li>
                        {catloaed ? categories.map(category => (
                            <li key={category.id}><button className={activeId === category.id ? 'active' : ''} onClick={function(event){ filterResult(category.id); setActiveId(category.id)}}>{category.name}</button></li>
                        )) : <h2>Loading...</h2>}
                    </ul>
                </div>
                
                {loaded ? currentArticles.map(article => (
                    <div key={article.slug} className="article">
                        <div className="image">
                            {article.featured_media === 0 &&
                                <img src="http://via.placeholder.com/640x360" alt="" />
                            }
                            {imgUrl.map(img => (
                                <Fragment key={img.id}>
                                    {img.id === article.featured_media &&
                                        <img  src={img.media_details.sizes.full.source_url} alt="" />
                                    }
                                </Fragment>
                            ))}
                            {categories.map(category => (
                                <Fragment key={category.id}>
                                    {article.categories == category.id &&
                                        <div  className="cat">{category.name}</div>
                                    }
                                </Fragment>
                            ))}
                        </div>
                        <div className="content">
                            <Link to={`/article/${article.slug}`}>
                                <h2>{article.title.rendered}</h2>
                                <p>
                                    <i className="icon icon__time"></i> 
                                    {new Date(article.date).toLocaleString('en-us', {day:"numeric"})}  &nbsp;
                                    {new Date(article.date).toLocaleString('en-us', { month:"long"})}  &nbsp;
                                    {new Date(article.date).toLocaleString('en-us', {year:"numeric" })}
                                </p>
                            </Link>
                        </div>
                    </div>
                )) : <h2>Loading...</h2>}

                <div className="pagination-wrapper">
                    <ul className="pagination">
                        {pageNumbers.length > 1 ? 
                        <Fragment>
                            {pageNumbers.map(number => (
                                <li key={number}>
                                    <button className={activePage === number ? 'active page_link' : 'page_link'} onClick={function (event) {paginate(number); setActivePage(number)}}>{number}</button>
                                </li>
                            ))}
                        </Fragment>    
                    : <li></li>}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Articles