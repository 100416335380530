
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Articles from "./components/Articles";
import ArticlePage from "./components/ArticlePage";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

function App() {
  return (
    <Router>
            <Navigation />
            <Routes>
                <Route exact path="/" element={<Articles />} />
                <Route exact path="/article/:id" element={<ArticlePage />} />
            </Routes>
            <Footer />
    </Router>
  );
}

export default App;
