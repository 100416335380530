import axios from "axios";
import { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";

function ArticlePage() {
    const [article, setArticle] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [categories, setCategories] = useState([]);
    const [catLoaded, setCatLoaded] = useState(false);
    const [imgUrls, setImgUrls] = useState([]);
    const [imgLoaded, setImgLoaded] = useState(false);
    const { id } = useParams();
    const [articles, setArticles] = useState([]);
    const [artLoaded, setArtLoaded] = useState(false);
    const [previousArticle, setPreviousArticle] = useState()
    const [nextArticle, setNextArticle] = useState();

    const env = 'http://alumoarticles.agletonline.co.za/wp-json/wp/v2';
    useEffect(() => {
        const fetchArticle = () => {
            axios.get(`${env}/posts?slug=${id}`)
            .then(res => {
                setArticle(res.data[0]);
                setLoaded(true);
            })
            .catch(err => console.log(err));
        }

        const fetchcategories = () => {
            axios.get(`${env}/categories/`)
            .then(res => {
                setCategories(res.data);
                setCatLoaded(true);
            })
            .catch(err => console.log(err));
            //setLoaded(true); 
        }

        const fetchImgUrl = () => {
            axios.get(`${env}/media/`)
            .then(res => {
                setImgUrls(res.data);
                setImgLoaded(true);
            })
            .catch(err => console.log(err));
        }

        fetchArticle();
        fetchcategories();
        fetchImgUrl();

        
    }, [])

    useEffect(() => {
        const allArticles = () => {
            axios.get(`${env}/posts`)
            .then(res => {
                setArticles(res.data);
                setArtLoaded(true);
            })
            .catch(err => console.log(err));
        }
        allArticles();
    }, [loaded])
    

    useEffect(() => {
        const fetchArticles = () => {
            axios.get(`${env}/posts`)
            .then(res => {
                let prevPost;
                let nextPost;

                for(let i = 0; i < res.data.length; i++) {
                    if(res.data[i].id === article.id) {
                        if(res.data[(i+1)]!== null) { 
                            prevPost = res.data[(i+1)];
                         
                        }

                        if(res.data[(i-1)] !== null) {
                         nextPost = res.data[(i-1)];
                        }
                    }
                }
                setPreviousArticle(prevPost);
                setNextArticle(nextPost);
                setArtLoaded(true);
                setArticles(res.data);
            })
            .catch(err => console.log(err))
        }

        fetchArticles();
    }, [loaded])
    let image = '';
    
    imgUrls.map(img => {
        if (img.id == article.featured_media) {
            image = img.media_details.sizes.full.source_url;
        }
    })

    let day = new Date(article.date).toLocaleString('en-us', {day:"numeric"});
    let month = new Date(article.date).toLocaleString('en-us', {month:"long"});
    let year = new Date(article.date).toLocaleString('en-us', {year:"numeric" })

    
  if(loaded) {
    return (
        <div>
            <div className="article__header" style={{ backgroundImage: `url(${image})` }}>
                <div className="container">
                    <div className="details">
                        {catLoaded ? categories.map(category => (
                            <Fragment key={category.id}>
                                {category.id == article.categories &&
                                    <div className="cat">{category.name}</div>
                                }
                            </Fragment>
                        )) : <p>Loading...</p>}
                        <h1>{article.title.rendered}</h1>

                        <div className="date"><p><i className="icon icon__time"></i> {day} {month} {year}</p></div>
                    </div>
                    <div className="socials">
                        <p>Share with friends</p>
                        <ul>
                            <li><a href="https://facebook.com" target="_blank" rel="noreferrer"><i className="icon icon__facebook"></i></a></li>
                            <li><a href="https://twitter.com" target="_blank" rel="noreferrer"><i className="icon icon__twitter"></i></a></li>
                            <li><a href="https://google.com" target="_blank" rel="noreferrer"><i className="icon icon__google"></i></a></li>
                            <li><a href="https://linkedin.com" target="_blank" rel="noreferrer"><i className="icon icon__linkedin"></i></a></li>
                            <li><a href="/"><i className="icon icon__email"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container article-content">
                <div dangerouslySetInnerHTML={{ __html: article.content.rendered }}></div>
            </div>
            <div className="other-articles">
                <div className="prev" style={{ backgroundImage: `url(${image})` }}>
                    {previousArticle !== undefined ? 
                    <a href={previousArticle.slug}>Discover more <br /> <strong>{previousArticle.title.rendered}</strong></a> : <a href={artLoaded ? articles[0].slug : ''}>Discover more <br /> <strong>{artLoaded ? articles[0].title.rendered : ''}</strong></a>}
                    {/* <a href="/">Discover more <br /> <strong>Article title goes here</strong></a> */}
                </div>
                <div className="next" style={{ backgroundImage: `url(${image})` }}>
                    {nextArticle !== undefined ? 
                    <a href={nextArticle.slug}>Discover more <br /> <strong>{nextArticle.title.rendered}</strong></a> : <a href={artLoaded ? articles[articles.length - 1].slug : ''}>Discover more <br /> <strong>{artLoaded ? articles[articles.length - 1].title.rendered : ''}</strong></a>}
                </div>
            </div>
        </div>
    )
  }
  return <h1>Loading...</h1>
}

export default ArticlePage