import React, { useState } from 'react';
import { ReactComponent as Logo } from '../assets/images/logo.svg';

function Navigation() {
    const [ darkNav, setDarkNav ] = useState(false);
    const [ navOpen, setNavOpen ] = useState(false);

    const changeNav = () => {
        if(window.scrollY > 100) {
            setDarkNav(true);
        } else {
            setDarkNav(false)
        }
    }

    window.addEventListener('scroll', changeNav)
  return (
    <nav id="nav" className={darkNav ? 'dark-nav' : ''}>
        <div className="container">
            <a href="/" className="logo">
                <Logo />
            </a>

            <button onClick={() => navOpen ? setNavOpen(false) : setNavOpen(true)} className="nav_toggler icon icon__menu"></button>

            <ul className={navOpen ? 'open' : ''}>
                <li className="dropdown">
                    <a href={void(0)}>Why Alumo</a>
                    <ul className="dropdown-menu">
                        <li><a href="/">Link 1</a></li>
                        <li><a href="/">Link 2</a></li>    
                    </ul>
                </li>
                <li className="dropdown">
                    <a href={void(0)}>How you pay</a>
                    <ul className="dropdown-menu">
                        <li><a href="/">Link 1</a></li>
                        <li><a href="/">Link 2</a></li>    
                    </ul>
                </li>
                <li className="dropdown">
                    <a href={void(0)}>What we do</a>
                    <ul className="dropdown-menu">
                        <li><a href="/">Link 1</a></li>
                        <li><a href="/">Link 2</a></li>    
                    </ul>
                </li>
                <li className="dropdown">
                    <a href={void(0)}>Contact us</a>
                    <ul className="dropdown-menu">
                        <li><a href="/">Link 1</a></li>
                        <li><a href="/">Link 2</a></li>    
                    </ul>
                </li>
                <li><button className="icon icon__search"></button></li>
                <li><a href="/" className="btn btn__primary">Start saving <i className="icon icon__btn"></i></a></li>
            </ul>
        </div>
    </nav>
  )
}

export default Navigation